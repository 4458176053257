import React from "react";
import { Link as RouterLink } from "react-router-dom";

import RightArrow from "../../../resources/images/common/icon-feather-arrow-right.png";
import { useTranslation } from "react-i18next";

const Card = ({
  target,
  imgWebp = "",
  imgSmall = "",
  img,
  imgSmallWebp = "",
  imgTitle = "",
  title,
  description,
  linkLabel = "",
  url = "",
  hasText,
  isAttachment = false,
}) => {
  const inputProps = {
    target: target ?? null,
  };

  url = url.replace("internal:", "");

  let picture = (
    <picture>
      <source media="(min-width:768px)" srcSet={imgWebp} type="image/webp" />
      <img src={img} alt={title} />
    </picture>
  );

  if (imgSmall) {
    picture = (
      <picture>
        <source media="(min-width:768px)" srcSet={imgWebp} type="image/webp" />
        <source media="(min-width:768px)" srcSet={img} type="image/jpeg" />
        <source srcSet={imgSmallWebp} type="image/webp" />
        <img src={imgSmall} alt={title} />
      </picture>
    );
  }

  const cardContent =
    url !== "" ? (
      url.includes("http") ? (
        <a target={"_blank"} rel={"noreferrer"} href={url} className={"link"}>
          {imgTitle && <div className="card-image-title">{imgTitle}</div>}
          {picture}
          {hasText && (
            <div className="content">
              <div className="content-title">{title}</div>
              <div
                className="content-description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          )}
          <div className="read-more">
            <div className="text">{linkLabel}</div>
            <div className="arrow-container">
              <img src={RightArrow} alt="" />
            </div>
          </div>
        </a>
      ) : (
        <RouterLink to={url} {...inputProps} className={"link"}>
          {imgTitle && <div className="card-image-title">{imgTitle}</div>}
          {picture}
          {hasText && (
            <div className="content">
              <div
                className="content-title"
                dangerouslySetInnerHTML={{ __html: title }}
              ></div>
              <div
                className="content-description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          )}
          <div className="read-more">
            <div className="text">{linkLabel}</div>
            <div className="arrow-container">
              <img src={RightArrow} alt="" />
            </div>
          </div>
        </RouterLink>
      )
    ) : (
      <div {...inputProps} className={"link"}>
        {imgTitle && <div className="card-image-title">{imgTitle}</div>}
        {picture}
        {hasText && (
          <div className="content">
            <div className="content-title">{title}</div>
            <div
              className="content-description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </div>
        )}
      </div>
    );

  return <div className="card">{cardContent}</div>;
};

export default Card;
