import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { NavMenuItem } from "../../interfaces/NavMenu";
import MenuItem from "./MenuItem";

const Accordion = withStyles({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },

  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    paddingRight: "12px",
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    display: "block",
    padding: 0,
    paddingLeft: "30px",
    backgroundColor: "#F4F8FA",
  },
})(MuiAccordionDetails);

const CollapsibleMenu = ({
  menuItem,
  level,
}: {
  menuItem: NavMenuItem;
  level?: number;
}) => {
  return (
    <Accordion square className="collapsible-menu">
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<KeyboardArrowDown />}
      >
        <MenuItem
          url={menuItem.url}
          roles={menuItem.roles}
          label={menuItem.label}
        />
      </AccordionSummary>
      <AccordionDetails className="collapsible-menu-details">
        <div className={`lvl${level}`}>
          {menuItem.child ? (
            Object.values(menuItem.child)?.map((menuItem) =>
              menuItem.child ? (
                <CollapsibleMenu menuItem={menuItem} />
              ) : (
                <MenuItem
                  url={menuItem.url}
                  roles={menuItem.roles}
                  label={menuItem.label}
                />
              )
            )
          ) : (
            <MenuItem
              url={menuItem.url}
              roles={menuItem.roles}
              label={menuItem.label}
            />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CollapsibleMenu;
