import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  takeLeading
} from "redux-saga/effects";
import { Api } from "../api/Api";
import { ATTEMPT_GET_USER_DATA } from "../constants/constants";
import ContentType from "../interfaces/ContentType";
import { PageType } from "../interfaces/Pages";
import {
  getReferencedMediaArray,
  replaceField, unwrapObjectResponse, unwrapResponse,
  UTCToLocalTimeString
} from "../util/util";
import {
  GET_CONDITIONS,
  GET_USER, updateConditions,
  updateCurrentUser
} from "./AuthActions";
import {
  GetContentAction,
  GetContentTypeAction,
  GET_CONTENT,
  GET_CONTENT_TYPE,
  updateContent,
  updateContents
} from "./PageContentActions";
import {
  finishedLoading,
  loading,
  TOOLBOX_FETCH_FAILED,
  TOOLBOX_FETCH_REQUESTED,
  TOOLBOX_FETCH_SUCCEEDED
} from "./ToolBoxActions";

function mapResponseToClientFormat(data) {
  if (data.content === "[]") {
    return [];
  }
  return Object.values(data).flat();
}

function* fetchMedications() {
  try {
    const medications = mapResponseToClientFormat(
      (yield call(Api.getMedications)).data.content
    ).map((medication: any) => {
      return {
        ...medication,
        startingDate: UTCToLocalTimeString(new Date(medication.startingDate)),
      };
    });

    yield put({
      type: TOOLBOX_FETCH_SUCCEEDED,
      data: {
        medications: medications,
      },
    });
  } catch (e) {
    yield put({ type: TOOLBOX_FETCH_FAILED, message: e.message });
  }
}

function* fetchGoals() {
  try {
    const goals = mapResponseToClientFormat(
      (yield call(Api.getGoals)).data.content
    ).map((goal: any) => {
      return {
        ...goal,
        date: UTCToLocalTimeString(new Date(goal.date)),
      };
    });

    yield put({
      type: TOOLBOX_FETCH_SUCCEEDED,
      data: {
        goals: goals,
      },
    });
  } catch (e) {}
}

function* fetchUserConditions() {
  try {
    yield put(loading(PageType.yourCondition));

    let conditions: Array<any> = Object.values(
      (yield call(Api.getUserConditions)).data.conditions
    );

    yield put({
      type: TOOLBOX_FETCH_SUCCEEDED,
      data: {
        conditions: conditions,
      },
    });

    conditions = conditions.map((c) => {
      return {
        ...c,
        condition: c.condition
          .toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll("'", "")
      };
    });

    yield put(updateConditions(conditions));
    yield put(finishedLoading(PageType.yourCondition, "", true));
  } catch (e) {
    yield put(finishedLoading(PageType.yourCondition, e, false));
  }
}

function* fetchContent(page: PageType) {
  try {
    yield put(loading(page));

    const content = (yield call(() => Api.getPageContent(page))).data.content;

    yield put(updateContent(page, content));
    yield put(finishedLoading(page, "", true));
  } catch (e) {
    yield put(finishedLoading(page, e, false));
  }
}

function* fetchContentType(
  contentType: ContentType,
  ...pages: Array<PageType>
) {
  try {
    yield put(loading(pages[0]));

    let content = unwrapResponse(
      yield call(() => Api.getNodeByType(contentType))
    ).map((res) => replaceField(res));

    const media = yield call(() =>
      getReferencedMediaArray(content, "attachment")
    );

    content.forEach((item, index) => {
      item.attachment =
        media.find((m) => m.mid === item.attachment?.target_id) || {};
    });

    yield put(updateContents({ [contentType]: content }, ...pages));
    yield put(finishedLoading(pages[0], "", true));
  } catch (e) {
    yield put(finishedLoading(pages[0], e, false));
  }
}

function* fetchAll() {
  yield put(loading(PageType.toolbox));
  yield all([
    call(fetchUserConditions),
    call(fetchMedications),
    call(fetchGoals),
    call(() =>
      fetchContentType(
        ContentType.SUGGESTED_GOAL,
        PageType.goalCreateCustom,
        PageType.goalEdit
      )
    ),
  ]);
  yield put(finishedLoading(PageType.toolbox, "", true));
}

function* getUser() {
  try {
    yield put(loading(ATTEMPT_GET_USER_DATA));
    yield put(loading(PageType.general));
    const user = (yield call(() => Api.getCurrentUser()))[0];

    user.roles = user.roles.toLowerCase();

    yield put(loading(PageType.yourCondition));

    const conditions = Object.values(
      (yield call(() => Api.getUserConditions())).data.conditions
    ).map((c: any) => {
      return {
        ...c,
        condition: c.condition
          .toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll("'", ""),
      };
    });

    let userInfo = {};
    if (user.uid !== '0') {
      userInfo = unwrapObjectResponse(yield call(() => Api.getUserInfo(user.uid)));
    }

    const csrfToken = yield call(() => Api.getCsrfToken());

    user.csrfToken = csrfToken;

    yield put(updateConditions(conditions));
    yield put(updateCurrentUser({...userInfo, ...user}));
    yield put(finishedLoading(PageType.yourCondition, "", true));
    yield put(finishedLoading(PageType.general, "", true));
    yield put(finishedLoading(ATTEMPT_GET_USER_DATA, "", true));
  } catch (e) {
    yield put(finishedLoading(PageType.yourCondition, "", false));
    yield put(finishedLoading(PageType.general, "", false));
    yield put(finishedLoading(ATTEMPT_GET_USER_DATA, "", false));
  }
}

function* mySaga() {
  yield takeLatest(TOOLBOX_FETCH_REQUESTED, fetchAll);
  yield takeLatest(GET_USER, getUser);
  yield takeLatest(GET_CONDITIONS, fetchUserConditions);
  yield takeEvery(GET_CONTENT, ({ page }: GetContentAction) =>
    fetchContent(page)
  );
  yield takeLeading(
    GET_CONTENT_TYPE,
    ({ page, contentType }: GetContentTypeAction) =>
      fetchContentType(contentType, page)
  );
}

export default mySaga;
