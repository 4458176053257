/* eslint-disable no-useless-computed-key */
import { createMuiTheme } from "@material-ui/core";

const skyBlue = "rgba(0, 194, 223, 1)";
const blue = "rgba(0, 99, 190, 1)";
const darkBlue = "rgba(0, 106, 180, 1)";
const interactiveOrange = "rgba(247, 169, 0, 1)";
const navy = "rgba(24, 44, 76, 1)";
const white = "#FFFFFF";

export const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
      },
    },
    MuiDialog: {
      paper: {
        minWidth: "580px",
      },
    },
    MuiFormControl: {
      root: {
        display: "flex",
      },
      marginNormal: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "140.5%",
        color: navy,
      },
    },
    MuiInput: {
      input: {
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "140.5%",
        color: navy,
        "&::placeholder": { color: "black" },
        padding: "15px 16px 15px 16px",
      },
      root: {
        background: "#FFFFFF",
        borderRadius: "10px",
        border: "2px solid #E0ECF2",
        maxWidth: "443px",
        height: "50px",
      },
    },
    MuiButton: {
      root: {
        background: interactiveOrange,
        boxShadow: "0px 2px 12px rgba(24, 44, 76, 0.139505)",
        borderRadius: "30px",
        height: "55px",
        width: "255px",
        left: "0px",
        top: "0px",
        fontWeight: 700,
        color: white,
        minWidth: "55px",

        "&.Mui-disabled": { color: white },
        "&:hover": {
          color: interactiveOrange,
        },
      },
      textPrimary: {
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "30px",
        letterSpacing: "0.4px",
        color: white,
      },
      label: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        letterSpacing: "1px",
      },
    },
    MuiTabs: {
      root: {
        border: "2px solid " + interactiveOrange,
        borderRadius: "8px",
      },
    },
    MuiTab: {
      root: {
        background: "transparent",
        height: "55px",
        left: "0px",
        top: "0px",
        fontWeight: 500,
        minWidth: "55px",
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: "0.4px",
        transition: "all 0.2s",
        flex: "1 0 50%",
        maxWidth: "none",

        "&:hover": {
          color: white,
          background: interactiveOrange,
        },
        "&$selected": {
          background: interactiveOrange,
          color: white,
        },
      },
      textColorPrimary: {
        color: interactiveOrange,

        "&:active": {
          color: white,
        },

        "&$selected": {
          color: white,
        },
      },
    },
    MuiTextField: {
      root: {},
    },
    MuiOutlinedInput: {
      input: {
        padding: "11.7px 14px",
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: navy,
      },
      colorSecondary: {
        color: interactiveOrange,
        fontWeight: 500,
      },
      colorTextSecondary: {
        color: navy,
      },
    },
    MuiGrid: {
      "grid-xs-6": {
        flexBasis: "auto",
      },
      "spacing-xs-2": {
        padding: "0px",
        "& $item": {
          padding: "0px",
        },
      },
    },
    MuiCircularProgress: {
      colorSecondary: {
        color: darkBlue,
      },
    },
    MuiContainer: {
      root: {
        padding: "0 165px 0 165px",
        ["@media (min-width:600px)"]: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        ["@media (min-width:800px)"]: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      },

      maxWidthLg: {
        maxWidth: "1440px",
        padding: "0 165px 0 165px",
        ["@media (max-width: 1365px)"]: {
          padding: "0px 25px 0px 23px",
          maxWidth: "768px",
        },
        ["@media (min-width: 1280px)"]: {
          maxWidth: "1440px",
        },
      },
    },
    MuiInputBase: {
      root: {
        height: "50px",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "white",
      },
      root: {
        height: "116px",
        justifyContent: "center",
      },
    },
    MuiBreadcrumbs: {
      li: {
        margin: "0px",
      },
      ol: {
        alignItems: "flex-start",
      },
      separator: {
        margin: "0px 8px 0px 8px",
      },
    },
    MuiDrawer: {
      paper: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "0 13px 0 0",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0px",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  palette: {
    secondary: {
      light: skyBlue,
      dark: darkBlue,
      main: blue,
      contrastText: white,
    },
    primary: {
      light: interactiveOrange,
      dark: interactiveOrange,
      main: interactiveOrange,
      contrastText: white,
    },
    background: {
      default: white,
    },
    text: {
      primary: navy,
    },
  },
  typography: {
    body2: {
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "0.4px",
      color: navy,
    },
    body1: {
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "32px",
      textAlign: "start",
      letterSpacing: "0.4px",
      color: "black",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "38px",
      lineHeight: "50px",
      letterSpacing: "0.4px",
      color: "#00C2DF",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "25px",
      lineHeight: "35px",
      letterSpacing: "0.4px",
      color: "#0063BE",
    },
    h5: {
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "30px",
      textAlign: "center",
      letterSpacing: "0.4px",
      color: "#0063BE",
    },
    h6: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "30px",
      textAlign: "start",
      letterSpacing: "0.4px",
      color: "white",
    },
    subtitle1: {
      fontWeight: 200,
      fontSize: "14px",
      lineHeight: "19px",
      letterSpacing: "0.4px",
      color: "white",
    },
    fontFamily: "Interstate",
  },
});
