/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Popup from "../Popup";

interface AuthLinkProps {
  className?: string;
}

export const RestrictedPagePopup = ({
  className = "",
}: AuthLinkProps) => {
  const history = useHistory();
  const [open, setOpen] = useState(true);

  const { t } = useTranslation();

  return (
      <Popup
        open={open}
        onButton1={() => {
          setOpen(false);
          history.push("/login");
        }}
        className={"restricted-popup"}
        title={t("restrictedPopup.title")}
        contentText={t("restrictedPopup.content")}
        button1Text={t("restrictedPopup.button.submit.label")}
      />
  );
};
