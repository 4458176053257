import { FormHelperText } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Api } from "../../api/Api";
import "../../assets/scss/ForgotPassword/ForgotPassword.scss";
import Dialog from "../../component/Dialog";
import Footer from "../../component/Footer/Footer";
import DynamicForm from "../../component/Form/DynamicForm";
import PageContainer from "../../component/PageContainer/PageContainer";
import { ATTEMPT_SEND_FORGOT_PASSWORD_EMAIL } from "../../constants/constants";
import useDisclosure from "../../hooks/useDisclosure";
import { GAEvent, GAEventCategory } from "../../interfaces/GAEvent";
import { ForgotPasswordPageType, PageType } from "../../interfaces/Pages";
import { ToolBoxData } from "../../interfaces/ToolBoxData";
import { contentState, loadingState, toolBoxState } from "../../store/Reducer";
import { finishedLoading, loading, updateResponse } from "../../store/ToolBoxActions";
import mapDataToEntityFormat, {
  createGAEvent,
  getLoadingData
} from "../../util/util";
import { getForgotPasswordFormStructure } from "./ForgotPasswordFormStructure";

function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const content: ForgotPasswordPageType = useSelector(contentState)[
    PageType.forgotPassword
  ];
  const { t } = useTranslation();
  const { isLoading, isSuccess } = getLoadingData(
    useSelector(loadingState),
    ATTEMPT_SEND_FORGOT_PASSWORD_EMAIL
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { msg }: ToolBoxData = useSelector(toolBoxState);

  const handleSubmit = (data) => {
    dispatch(loading(ATTEMPT_SEND_FORGOT_PASSWORD_EMAIL));

    Api.forgotPassword(mapDataToEntityFormat(data))
      .then((resp) => {
        createGAEvent(
          GAEvent.FORM_SUBMISSION,
          GAEventCategory.PASSWORD_RESET_REQUESTED
        );
        onOpen();
        dispatch(
          finishedLoading(
            ATTEMPT_SEND_FORGOT_PASSWORD_EMAIL,
            resp.message,
            true
          )
        );
      })
      .catch((error) => {
        dispatch(updateResponse({msg : error.message}));
        dispatch(
          finishedLoading(
            ATTEMPT_SEND_FORGOT_PASSWORD_EMAIL,
            error.message,
            false
          )
        );
      });
  };

  const formStructure = getForgotPasswordFormStructure(t);

  return (
    <>
      <PageContainer dataType={PageType.forgotPassword}>
        <DynamicForm
          formStructure={formStructure}
          onSubmit={handleSubmit}
          loading={isLoading}
          success={isSuccess}
        >
          <FormHelperText error>{msg}</FormHelperText>
        </DynamicForm>
        <Dialog
          open={isOpen}
          button1Text={t("forgotPasswordFormStructure.dialog.close")}
          onButton1={() => {
            onClose();
            history.push("/login");
          }}
          contentText={t("forgotPasswordFormStructure.dialog.message")}
          title={t("forgotPasswordFormStructure.dialog.title")}
          className="form"
        />
      </PageContainer>
      <Footer legal_number={content?.legal_number} />
    </>
  );
}

export default ForgotPasswordPage;
