import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "../../assets/scss/Footer/Footer.scss";
import { FooterContentType } from "../../interfaces/FooterType";
import { PageType } from "../../interfaces/Pages";
import logo from "../../resources/logo/fresenius-kabi.png";
import { authState, contentState } from "../../store/Reducer";
import { generateNav, useQuery } from "../../util/util";
import DynamicFooter from "./DynamicFooter";

export default function Footer(props) {
  const location = useLocation().pathname.replace("/", "");
  let mobile = useQuery().get("mobile") === "true";
  const content = useSelector(contentState);
  const auth = useSelector(authState);

  if (mobile) return <></>;

  const footerContent: FooterContentType =
    content[location]?.footer ||
    content?.login?.footer ||
    content[PageType.homeOld]?.footer;

  let legalNumber = "";
  if (content[location] && content[location].legal_number) {
    if (
      props.legal_number &&
      props.legal_number !== "XX/XXX/XXXX" &&
      props.legal_number !== "XX/XX/XXX" &&
      (content[location].legal_number === "XX/XXX/XXXX" ||
        content[location].legal_number === "")
    ) {
      legalNumber = props.legal_number;
    } else {
      legalNumber = content[location].legal_number;
    }
  } else if (props.legal_number) {
    legalNumber = props.legal_number;
  }

  if (content[location] && content[location].footer_dynamic) {
    return (
      <DynamicFooter
        {...content[location].footer_dynamic}
        legal_number={legalNumber}
      />
    );
  }

  if (!footerContent) {
    return <></>;
  }

  return (
    <div className={"footer"}>
      <div className="footer-inner-container">
        {(footerContent.footer_disclaimer_situational_visible ||
          auth.authenticated) && (
          <div
            className={"disclaimer situational"}
            dangerouslySetInnerHTML={{
              __html: footerContent?.footer_disclaimer_situational,
            }}
          ></div>
        )}
        <div
          className={"disclaimer"}
          dangerouslySetInnerHTML={{
            __html: footerContent?.footer_disclaimer_normal,
          }}
        ></div>
        <div className={"bottom"}>
          <div className={"logo"}>
            <img src={logo} alt="Logo" width="238" height="64" />
          </div>
          <div className={"content"}>
            <div className="preparation-container">
              <div className={"date"}>
                <p>{footerContent?.footer_date_of_preparation_label}</p>
              </div>
              <div className="legal-number">{legalNumber}</div>
            </div>
            <div className={"links"}>
              {generateNav(footerContent?.footer_menu)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
