import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import eng from "./en/ns.json";
import sp from "./es/ns.json";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: eng,
  },
  es: {
    translation: sp,
  }
} as const;

i18n.use(initReactI18next).init({
  lng: "es",
  fallbackLng: "es",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
