import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid " + theme.palette.primary.main,
    filter: "drop-shadow(0px 2px 12px rgba(24, 44, 76, 0.139505))",
    borderRadius: "30px",
    background: "transparent",
    color: theme.palette.primary.main,
    width: "326px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  checkIcon: {
    color: "green",
    marginLeft: "15px",
  },
  spinner: {
    marginLeft: "15px",
  },
}));

export function InverseButton({
  children,
  loading = false,
  success = false,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Button classes={classes} aria-label={children} {...rest}>
      {children}
      {loading && (
        <CircularProgress
          className={classes.spinner}
          size={20}
          color="secondary"
        />
      )}
      {success && <CheckIcon className={classes.checkIcon} />}
    </Button>
  );
}
