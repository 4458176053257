import React, { createRef, useEffect, useState } from "react";
import "../../assets/scss/Common/Common.scss";
import { CarouselItem } from "../../interfaces/Pages";

interface LowerHomePageSectionProps {
  carouselCards: Array<CarouselItem>;
  inactiveCard: CarouselItem;
  setHeroContent: (content: CarouselItem) => void;
  setInactiveCard: (item: CarouselItem) => void;
  setCarouselCards: (items: Array<CarouselItem>) => void;
}

export const LowerHomePageSection = ({
  carouselCards,
  inactiveCard,
  setHeroContent,
  setInactiveCard,
  setCarouselCards,
}: LowerHomePageSectionProps) => {
  const [itemRefs, setItemRefs] = useState<Array<any>>([]);

  useEffect(() => {
    setItemRefs([...Array(12).keys()].map((i) => createRef()));
  }, []);

  const swapHeroImage = (item: CarouselItem) => {
    setHeroContent(item);

    handleCarouselSelect(item);
  };

  const handleCarouselSelect = (item: CarouselItem) => {
    const newCarouselCards = carouselCards.filter(
      (card) => item.nid !== card.nid
    );

    setCarouselCards([...newCarouselCards, inactiveCard]);

    setInactiveCard(item);
  };

  return (
    <div className="lower-section">
      <div className="home-page-carousel">
        {carouselCards.map((item, i) => (
          <div
            ref={itemRefs[i]}
            className={"carousel-item"}
            key={i}
            onClick={() => swapHeroImage(item)}
          >
            <picture>
              <source
                media="(min-width:768px)"
                srcSet={item.field_homepage_carousel_item_img.url}
              />
              <source srcSet={item.field_hp_carousel_item_img_small?.url} />
              <img
                src={item.field_hp_carousel_item_img_small?.url}
                alt={item.field_homepage_carousel_item_img.alt}
                height="335"
                width="158"
              />
            </picture>
            <div className="text">
              <h2 dangerouslySetInnerHTML={{ __html: item.title.replace('Kabicare', 'KabiCare') || "" }}></h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
